import { CellContext, ColumnDef } from '@tanstack/react-table'
import { store } from 'store/index'
import { NameCell, PeopleCell, SkillGapCell, CoverageCell } from './cells'

export type OrgSkillGapsTableRow = {
  id: string
  variantId: string
  name: string
  imageUrl?: string | null
  description: string | null
  first3UserIds: string[]
  teamIds: string[]
  userCount: number
  averageRequiredLevel: number
  averageAssessedLevel: number
  averageAssessedRequiredLevel: number
  assessedSkillGap: number
  checkinCoverage: number
  checkinCount: number
}

type HeaderContent = any
type HeaderItem = { key: string; value: string }

const findHeader = (key: string, headerContent: HeaderItem[]) =>
  headerContent.find((item: HeaderItem) => item.key === key)?.value

export class Data {
  getColumnsWithHeaderContent(headerContent: HeaderContent) {
    return [
      {
        accessorKey: 'name',
        cell: NameCell,
        enableGlobalFilter: true,
        header: findHeader('name', headerContent),
        id: 'name',
      },
      {
        accessorKey: 'userCount',
        enableGlobalFilter: false,
        id: 'users',
        cell: PeopleCell,
        header: findHeader('user_count', headerContent),
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'checkinCoverage',
        enableGlobalFilter: false,
        id: 'checkinCoverage',
        cell: CoverageCell,
        header: findHeader('checkin_coverage', headerContent),
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'averageAssessedRequiredLevel',
        enableGlobalFilter: false,
        id: 'averageAssessedRequiredLevel',
        header: findHeader('average_assessed_required_level', headerContent),
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'averageAssessedLevel',
        enableGlobalFilter: false,
        id: 'averageAssessedLevel',
        header: findHeader('average_assessed_level', headerContent),
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'assessedSkillGap',
        enableGlobalFilter: false,
        id: 'assessedSkillGap',
        cell: SkillGapCell,
        header: findHeader('assessed_skill_gap', headerContent),
        sortingFn: (rowA, rowB, columnId) => {
          return rowA.original.assessedSkillGap - rowB.original.assessedSkillGap
        },
      },
    ] satisfies ColumnDef<OrgSkillGapsTableRow, never>[]
  }

  get variantsEnabled() {
    return store.featureGates.canAccess('skill_variants')
  }
}
