import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Data, OrgSkillGapsTableRow } from '../data'
import { fuzzyFilter } from 'app/packs/src/utils/table-helpers'
import { SkeletonCell } from 'components/table/skeleton'
import { store } from 'store/index'

type TableContextProps = {
  columnFilters: ColumnFiltersState
  setColumnFilters: (value: ColumnFiltersState) => void
  data: OrgSkillGapsTableRow[]
  globalFilter: string
  setGlobalFilter: (value: string) => void
  table: Table<OrgSkillGapsTableRow>
  loading: boolean
}

const TableContext = React.createContext<TableContextProps | null>(null)

export const TableProvider = observer(
  ({
    loading = false,
    children,
    data,
    headerContent,
  }: {
    children: React.ReactNode | ((ctx: TableContextProps) => React.ReactNode)
    loading?: boolean
    data: OrgSkillGapsTableRow[]
    headerContent: object
  }) => {
    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const [globalFilter, setGlobalFilter] = React.useState('')
    const vm = React.useMemo(() => new Data(), [])

    const columns = React.useMemo(() => {
      const cols = vm.getColumnsWithHeaderContent(headerContent)
      if (!loading) return cols

      return cols.map((col) => {
        if (col.id && ['name', 'lastEdited'].includes(col.id)) return col
        return {
          ...col,
          cell: SkeletonCell,
        } as ColumnDef<OrgSkillGapsTableRow, unknown>
      })
    }, [vm, loading])

    const table = useReactTable({
      columns,
      data,
      filterFns: { fuzzy: fuzzyFilter },
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        sorting: [{ id: 'users', desc: true }],
        pagination: {
          pageSize: 200,
          pageIndex: 0,
        },
      },
      state: {
        columnFilters,
        columnVisibility: {
          editPermission: false,
          needAttention: false,
          teams: false,
          users: store.nonNullCurrentUser.isAdmin,
          variantCount: vm.variantsEnabled,
        },
        globalFilter,
      },
    })

    const contextValue = {
      columnFilters,
      setColumnFilters,
      data,
      globalFilter,
      setGlobalFilter,
      table,
      loading,
      headerContent,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <TableContext.Provider value={contextValue}>
        {content}
      </TableContext.Provider>
    )
  }
)

export const useTable = () => {
  const context = React.useContext(TableContext)

  if (context === null) {
    throw new Error('useTable must be used within a TableProvider')
  }

  return context
}
