import { Button, DrawerButton, SKILLS_DRAWER_ID } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillsDrawer } from 'components/skills-drawer'
import { store } from 'store/index'
import { useDrawerSkills } from 'app/packs/src/utils/use-drawer-skills'
import { WithAnalytics } from 'components/with-analytics'
import * as SecondaryNav from 'components/secondary-nav'
import { DownloadSimple } from '@phosphor-icons/react'

type NavProps = {
  defaultOpen?: boolean
  onRefresh?: () => void
}

export const Nav = observer((props: NavProps) => {
  const { onRefresh, defaultOpen } = props

  const source = 'library'

  const { onClickCollection } = useDrawerSkills(source)

  const canAddSkills = !!store.currentUser && store.currentUser.isAdminOrEditor
  const canImportSkills = !!store.currentUser && store.currentUser.isAdmin
  const downloadLink = `/orgs/${store.currentUser?.org?.slug}/skills/download.csv`

  return (
    <>
      <SecondaryNav.Root>
        <div className="flex items-center justify-between w-full">
          <SecondaryNav.Header>Skills</SecondaryNav.Header>
          {(canAddSkills || canImportSkills) && (
            <div className="flex gap-2 items-center">
              {canImportSkills && (
                <>
                  <WithAnalytics
                    event="$track_org_secondary_nav_click"
                    params={{ button: 'Import from CSV' }}
                  >
                    <Button
                      as="a"
                      href="/skill_imports/new"
                      variant="outline"
                      data-turbo-action="advance"
                    >
                      Import from CSV
                    </Button>
                  </WithAnalytics>
                  <Button
                    as="a"
                    href={downloadLink}
                    variant="outline"
                    data-turbo-action="advance"
                  >
                    <DownloadSimple aria-hidden size={16} weight="bold" />
                    Download data
                  </Button>
                </>
              )}
              {canAddSkills && (
                <WithAnalytics
                  event="$track_org_secondary_nav_click"
                  params={{ button: 'Add skills' }}
                >
                  <DrawerButton drawerId={SKILLS_DRAWER_ID}>
                    Add skills
                  </DrawerButton>
                </WithAnalytics>
              )}
            </div>
          )}
        </div>
      </SecondaryNav.Root>
      {canAddSkills && (
        <SkillsDrawer
          defaultOpen={defaultOpen}
          onClickCollection={onClickCollection}
          refreshProps={() => onRefresh?.()}
          source={source}
          title="Add skills to your org"
        />
      )}
    </>
  )
})
