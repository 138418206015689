import * as React from 'react'
import { OrgSkillGapsTable } from './table'
import { TableProvider } from './table/context/table'
import { useRailsContext } from 'components/rails-context'
import { deserialize } from '../../api/utils/deserialize'
import { OrgSkillGapsTableRow } from './table/data'
import { InsightsSecondaryNav } from 'components/insights-secondary-nav'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'

export type OrgSkillGapsPageProps = {
  headerContent: object
}

export const OrgSkillGapsPage: React.VFC<OrgSkillGapsPageProps> = observer(
  ({ headerContent }) => {
    const [allLoading, setAllLoading] = React.useState(true)
    const [data, setData] = React.useState<OrgSkillGapsTableRow[] | null>(null)
    const [team, setTeam] = React.useState<string | string[] | null>(null)
    const { skills } = store
    const defaultRows = React.useMemo(() => {
      return skills.forOrg.flatMap(toRow)
    }, [skills])

    const fetchAllSkills = async (team: string | string[] | null) => {
      const rows = await fetchSkillInsights(team)
      setData(rows)
      setAllLoading(false)
    }
    React.useEffect(() => {
      fetchAllSkills(null)
    }, [])

    React.useEffect(() => {
      fetchAllSkills(team)
    }, [team])

    React.useEffect(() => {
      // Don't start listening for changes until all the initial store boot has finished
      if (allLoading) return
    }, [allLoading])

    const { request } = useRailsContext()

    const defaultOpen = request.query.open_drawer ? true : false

    return (
      <>
        <InsightsSecondaryNav />
        {/* <Nav onRefresh={() => fetchAllSkills(null)} defaultOpen={defaultOpen} /> */}
        <TableProvider
          loading={allLoading}
          data={data || defaultRows}
          headerContent={headerContent}
        >
          <OrgSkillGapsTable headerContent={headerContent} setTeam={setTeam} />
        </TableProvider>
      </>
    )
  }
)

async function fetchSkillInsights(team?: string | string[] | null) {
  const teamArg = team ? `&filter_team_id=${team}` : ''
  const res = await fetch(
    `/api/v1/skill_insights?skill_gap_data=true${teamArg}`
  )
  if (!res.ok) return []
  const json = await res.json()
  const deserialized = deserialize(json)
  if (!deserialized?.data) return []
  const insights = deserialized.data.skillInsights as Record<
    string,
    OrgSkillGapsTableRow
  >

  return Object.values(insights).map(normalizeRow)
}

function normalizeRow(row: OrgSkillGapsTableRow) {
  return {
    ...row,
    teamIds: row.teamIds.map((id) => id.toString()),
    variantId: row.variantId.toString(),
  }
}

function toRow(skill: Skill): OrgSkillGapsTableRow | [] {
  const variant = skill.defaultVariant
  if (!variant) return []
  return {
    id: skill.id,
    name: skill.name,
    imageUrl: skill.imageUrl,
    teamIds: [],
    first3UserIds: [],
    userCount: 0,
    variantId: variant.id,
    description: skill.description,
    averageRequiredLevel: 0,
    averageAssessedLevel: 0,
    averageAssessedRequiredLevel: 0,
    assessedSkillGap: 0,
    checkinCoverage: 0,
    checkinCount: 0,
  }
}
