import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { OrgSkillGapsTableRow } from '../data'

export const NameCell = observer(
  (data: CellContext<OrgSkillGapsTableRow, unknown>) => {
    const row = data.row.original

    return (
      <div className="flex gap-x-2 items-center">
        <div
          className="border border-white bg-image-cover flex-shrink-0 h-8 rounded w-8"
          style={{ backgroundImage: `url("${row.imageUrl}")` }}
        />
        <SkillModalButton
          className="font-bold text-gray-900 text-sm flex gap-3 hover:underline"
          skillId={row.id}
          skillVariantId={row.variantId}
          source="org-skills-page-skill-name"
        >
          {row.name}
        </SkillModalButton>
      </div>
    )
  }
)
