import { Button } from 'src/design-system'
import { flexRender } from '@tanstack/react-table'
import { MinusCircle, UsersThree } from '@phosphor-icons/react'

import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SearchFilter } from 'components/search-filter'
import { Select } from 'components/atoms/select'
import { useTable } from './context/table'
import * as Table from 'components/table'
import { Filters } from './filters'
import { DownloadButton } from '../download-button'

type OrgSkillGapsTableProps = {
  headerContent: any
  setTeam: (value: string | string[]) => void
}

export const OrgSkillGapsTable = observer(
  ({ headerContent, setTeam }: OrgSkillGapsTableProps) => {
    const {
      columnFilters,
      data,
      globalFilter,
      setGlobalFilter,
      table,
      loading,
    } = useTable()

    const source = 'org-skill-gaps-table'

    const filterValue = (columnId: string) => {
      return columnFilters.find((column) => column.id === columnId)?.value
    }

    const filters = React.useMemo(() => new Filters(data), [data])

    const onClearFilters = () => {
      table.resetColumnFilters()
      table.resetGlobalFilter()
      table.resetSorting()
    }

    const onFilterChange = (column: string, value: string | string[]) => {
      table.getColumn(column)?.setFilterValue(value)
    }

    const onTeamChange = (value: string | string[]) => {
      setTeam(value)
    }

    const showClearFiltersButton = React.useMemo(() => {
      return columnFilters.length > 0 || (globalFilter && globalFilter !== '')
    }, [columnFilters, globalFilter])

    const getTooltipContent = (header: any, headerContent: any) => {
      if (headerContent) {
        return headerContent.find(
          (item: any) => item.value === header.column.columnDef.header
        )?.info
      }
    }

    return (
      <div>
        <div className="flex flex-col gap-6">
          <div className="px-6 pt-6 flex justify-between">
            <div className="flex flex-wrap gap-2">
              <SearchFilter
                name="org-skills-search"
                onChange={(value) => {
                  setGlobalFilter(String(value))
                }}
                value={globalFilter ?? ''}
              />
              <Select
                isMulti
                isSearchable
                onChange={(values: string[]) => onTeamChange(values)}
                options={filters.teamOptions}
                triggerContent={<TeamTriggerContent />}
                value={filterValue('teams') as string[] | undefined}
              />
              {showClearFiltersButton && (
                <Button
                  className="h-[34px]"
                  onClick={onClearFilters}
                  variant="outline"
                >
                  <MinusCircle
                    aria-hidden
                    className="h-4 text-theme-40 w-4"
                    weight="bold"
                  />
                  Clear filters
                </Button>
              )}
            </div>
            <div className="">
              <DownloadButton />
            </div>
          </div>
          <div className="overflow-x-auto">
            <Table.Root>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Table.Header
                          key={header.id}
                          header={header}
                          loading={loading}
                          source={source}
                          tooltipContent={getTooltipContent(
                            header,
                            headerContent
                          )}
                          pinned={
                            header.column.columnDef.header === 'Skill Name'
                              ? 'left'
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Table.Header>
                      )
                    })}
                  </Table.Row>
                ))}
              </Table.Head>

              <Table.Body>
                {table.getRowModel().rows.length === 0 && (
                  <Table.NoResultsRow colspan={table.getAllColumns().length} />
                )}

                {table.getRowModel().rows.map((row, index) => {
                  const cells = row.getVisibleCells()

                  return (
                    <Table.Row
                      showBottomBorder={
                        index < table.getRowModel().rows.length - 1
                      }
                      key={row.id}
                    >
                      {cells.map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          pinned={
                            cell.column.columnDef.header === 'Skill Name'
                              ? 'left'
                              : undefined
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </div>
          <div className="px-6 pb-6 flex justify-center">
            <Table.Pagination table={table} />
          </div>
        </div>
      </div>
    )
  }
)

const TeamTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <UsersThree aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All teams
    </div>
  )
}
