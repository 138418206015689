import {
  Button,
  GlobalDrawer,
  useDrawerContext,
  useDrawerProps,
} from 'src/design-system'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { observer } from 'mobx-react-lite'
import { ArrowSquareOut, X } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'
import { PeopleSection } from './people-section'
import { UsedBySection } from './used-by-section'
import { LoadingContent } from 'components/loading-content'

export const SKILL_INSIGHTS_DRAWER_ID = 'skill-insights-drawer'
export type SkillInsightsDrawerProps = {
  skillId: string
}

export const SkillInsightsDrawer = observer(() => {
  return (
    <GlobalDrawer
      className="sm:w-[400px] md:w-[500px]"
      id={SKILL_INSIGHTS_DRAWER_ID}
      overlay={false}
      title="Skill Insights"
    >
      <BaseSkillInsightsDrawer />
    </GlobalDrawer>
  )
})

const BaseSkillInsightsDrawer: React.VFC = () => {
  const drawerContext = useDrawerContext()
  const [loading, setLoading] = React.useState(true)

  const { skillId } = useDrawerProps<SkillInsightsDrawerProps>(
    SKILL_INSIGHTS_DRAWER_ID
  )

  const closeDrawer = () => {
    drawerContext?.closeDrawer()
  }

  React.useEffect(() => {
    const fetchPreview = async (abortSignal: AbortSignal) => {
      if (!skillId) return
      await store.skills.fetchOne(
        skillId,
        {
          include: [
            'frameworks_skills',
            'frameworks_skills.framework',
            'frameworks_skills.framework.team',
            'requirements',
            'skill_variants',
            'skill_variants.skill_levels',
            'skill_variants.skill_levels.outcomes',
          ],
        },
        { abortSignal }
      )

      setLoading(false)
    }

    const controller = new AbortController()
    fetchPreview(controller.signal)
    return () => controller.abort()
  }, [skillId])

  const skill = store.skills.byId(skillId)
  if (!skill) return null

  const { defaultVariant } = skill

  return (
    <div className={'h-full relative z-20 isolate'}>
      <button
        onClick={closeDrawer}
        className="absolute top-4 right-4 z-10 bg-white rounded-full p-2 w-8 h-8 flex justify-center items-center border-px border-gray-50 border-solid"
      >
        <X className="h-4 text-gray-900 w-4" weight="bold" />
        <span className="sr-only">Close</span>
      </button>
      <article className="flex flex-col gap-10 lg:fixed h-screen overflow-auto p-6 w-full">
        <header className="flex flex-col gap-8 relative">
          {skill.imageUrl && (
            <img
              className="h-40 object-cover -mx-6 -mt-6"
              src={skill.imageUrl}
            />
          )}
          <hgroup className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <h2 className="font-bold text-3xl mr-auto">{skill.name}</h2>
              <SkillModalButton
                skillId={skill.id}
                skillVariantId={skill.defaultVariant?.id}
                source="org-skills-page-insight-drawer"
              >
                <Button
                  size="sm"
                  variant="outline"
                  className="flex items-center"
                >
                  <ArrowSquareOut weight="bold" /> View Skill
                </Button>
              </SkillModalButton>
            </div>
            {skill.description && (
              <p
                className="text-base"
                dangerouslySetInnerHTML={{ __html: skill.description }}
              />
            )}
          </hgroup>
        </header>
        <LoadingContent loading={loading}>
          <UsedBySection skill={skill} />
          {defaultVariant && <PeopleSection variant={defaultVariant} />}
        </LoadingContent>
      </article>
    </div>
  )
}
