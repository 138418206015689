import { forRails } from 'components/for-rails'
import { OrgSkillsPage } from 'pages/org-skills-page'
import { OrgSkillGapsPage } from 'pages/org-skill-gaps-page'
import { registerComponents } from '../src/utils/react/registry'
import { SkillEditableByLabel } from 'components/skill-editable-by-label'
import { SkillVariantDialog } from 'components/skill-variant-dialog'

registerComponents({
  OrgSkillsPage: forRails(OrgSkillsPage),
  OrgSkillGapsPage: forRails(OrgSkillGapsPage),
  SkillEditableByLabel: forRails(SkillEditableByLabel),
  SkillVariantDialog: forRails(SkillVariantDialog),
})
