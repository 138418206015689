import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OrgSkillGapsTableRow } from '../data'

export const SkillGapCell = observer(
  (data: CellContext<OrgSkillGapsTableRow, unknown>) => {
    const row = data.row.original

    const progress = row.assessedSkillGap

    const getProgressColor = (assessedSkillGap: number) => {
      let progressColor: string
      let textColor: string
      switch (true) {
        case assessedSkillGap > 0.4:
          progressColor = 'bg-green-500/75'
          textColor = 'text-green-900'
          break
        case assessedSkillGap > 0.2:
          progressColor = 'bg-green-500/50'
          textColor = 'text-green-800'
          break
        case assessedSkillGap > 0:
          progressColor = 'bg-green-500/25'
          textColor = 'text-green-800'
          break
        case assessedSkillGap < -0.01:
          progressColor = 'bg-yellow-500/25'
          textColor = 'text-yellow-800'
          break
        case assessedSkillGap < -0.2:
          progressColor = 'bg-yellow-500/50'
          textColor = 'text-yellow-800'
          break
        case assessedSkillGap < -0.4:
          progressColor = 'bg-red-500/75'
          textColor = 'text-red-900'
          break
        default:
          progressColor = 'bg-gray-100/50'
          textColor = 'text-gray-800'
          break
      }
      return { progressColor, textColor }
    }

    return (
      <div className="flex">
        <div
          className={`rounded-full flex-grow-0 min-w-24 flex justify-center px-3 py-0.5 ${
            getProgressColor(progress).progressColor
          }`}
        >
          <span className={`${getProgressColor(progress).textColor}`}>
            {progress <= 0 ? '' : '+'}
            {(progress * 100).toFixed(0)}%
          </span>
        </div>
      </div>
    )
  }
)
