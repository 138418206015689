import { Button, useDrawerContext } from 'src/design-system'
import { flexRender } from '@tanstack/react-table'
import { MinusCircle, Pencil, UsersThree } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OverviewCards, OverviewCardType } from '../overview-cards'
import { SearchFilter } from 'components/search-filter'
import { Select } from 'components/atoms/select'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { useTable } from './context/table'
import * as Table from 'components/table'
import { Filters } from './filters'
import {
  SKILL_INSIGHTS_DRAWER_ID,
  SkillInsightsDrawer,
  SkillInsightsDrawerProps,
} from '../side-panel'

export const OrgSkillsTable = observer(() => {
  const { openDrawer } = useDrawerContext()
  const { columnFilters, data, globalFilter, setGlobalFilter, table, loading } =
    useTable()

  const source = 'org-skills-table'

  const isAdmin = !!store.currentUser?.isAdmin
  const isAdminOrEditor = !!store.currentUser?.isAdminOrEditor

  const [selectedCard, setSelectedCard] =
    React.useState<OverviewCardType>('total')

  const filterValue = (columnId: string) => {
    return columnFilters.find((column) => column.id === columnId)?.value
  }

  const filters = React.useMemo(() => new Filters(data), [data])

  const onClearFilters = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
    setSelectedCard('total')
  }

  const onFilterChange = (column: string, value: string | string[]) => {
    table.getColumn(column)?.setFilterValue(value)
    trackEvent('$track_org_skills_filter_changed', { [column]: value })
  }

  const onOverviewCardClick = (overviewCardType: OverviewCardType) => {
    trackEvent('$track_org_skills_card_clicked', { card: overviewCardType })

    switch (overviewCardType) {
      case 'needAttention':
        table.getColumn('needAttention')?.setFilterValue(true)
        table.getColumn('positions')?.setFilterValue(undefined)
        break
      case 'total':
        table.getColumn('needAttention')?.setFilterValue(undefined)
        table.getColumn('positions')?.setFilterValue(undefined)
        break
      case 'unused':
        table.getColumn('needAttention')?.setFilterValue(undefined)
        table.getColumn('positions')?.setFilterValue(0)
    }
  }

  const showClearFiltersButton = React.useMemo(() => {
    return columnFilters.length > 0 || (globalFilter && globalFilter !== '')
  }, [columnFilters, globalFilter])

  return (
    <div>
      {isAdminOrEditor && (
        <OverviewCards
          needAttentionCount={filters.needAttentionCount(
            table.getState().columnFilters,
            globalFilter
          )}
          onClick={(overviewCardType) => onOverviewCardClick(overviewCardType)}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          totalCount={filters.totalCount(
            table.getState().columnFilters,
            globalFilter
          )}
          unusedCount={filters.unusedCount(
            table.getState().columnFilters,
            globalFilter
          )}
        />
      )}
      <div className="pt-6">
        <div className="flex flex-wrap gap-2 mb-5 px-4">
          <SearchFilter
            name="org-skills-search"
            onChange={(value) => {
              setGlobalFilter(String(value))
            }}
            value={globalFilter ?? ''}
          />
          <Select
            isMulti
            isSearchable
            onChange={(values: string[]) => onFilterChange('teams', values)}
            options={filters.teamOptions}
            triggerContent={<TeamTriggerContent />}
            value={filterValue('teams') as string[] | undefined}
          />
          {isAdminOrEditor && (
            <Select
              onChange={(value: string) =>
                onFilterChange('editPermission', value)
              }
              options={filters.editPermissionOptions}
              triggerContent={<EditPermissionTriggerContent />}
              value={filterValue('editPermission') as string}
            />
          )}
          {showClearFiltersButton && (
            <Button
              className="h-[34px]"
              onClick={onClearFilters}
              variant="outline"
            >
              <MinusCircle
                aria-hidden
                className="h-4 text-theme-40 w-4"
                weight="bold"
              />
              Clear filters
            </Button>
          )}
        </div>
        <div className="overflow-x-auto mt-10">
          <Table.Root>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Table.Header
                        key={header.id}
                        header={header}
                        loading={loading}
                        source={source}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Table.Header>
                    )
                  })}
                </Table.Row>
              ))}
            </Table.Head>

            <Table.Body>
              {table.getRowModel().rows.length === 0 && (
                <Table.NoResultsRow colspan={table.getAllColumns().length} />
              )}

              {table.getRowModel().rows.map((row, index) => {
                const cells = row.getVisibleCells()

                return (
                  <Table.Row
                    showBottomBorder={
                      index < table.getRowModel().rows.length - 1
                    }
                    key={row.id}
                    onClick={
                      isAdmin
                        ? (e) => {
                            if (e.target instanceof HTMLButtonElement) return
                            if (
                              (e.target as HTMLElement).closest(
                                '[role="dialog"]'
                              )
                            )
                              return
                            openDrawer<SkillInsightsDrawerProps>(
                              SKILL_INSIGHTS_DRAWER_ID,
                              { skillId: row.original.id }
                            )
                          }
                        : undefined
                    }
                  >
                    {cells.map((cell) => (
                      <Table.Cell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table.Root>
          <div className="px-3 flex justify-center">
            <Table.Pagination table={table} />
          </div>
          {isAdmin && <SkillInsightsDrawer />}
        </div>
      </div>
    </div>
  )
})

const EditPermissionTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <Pencil aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All edit permissions
    </div>
  )
}

const TeamTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <UsersThree aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All teams
    </div>
  )
}
