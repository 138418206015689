import * as React from 'react'
import { DrawerContext } from '../../../contexts/drawer-context'
import { PropsWithRequiredChildren } from '../../../types/helpers'
import { Button, ButtonOwnProps } from '../../atoms/Button'

type DrawerButtonProps = {
  drawerId: string
} & PropsWithRequiredChildren<
  ButtonOwnProps & React.ComponentPropsWithoutRef<'button'>
>

export const DrawerButton: React.VFC<DrawerButtonProps> = (props) => {
  const { drawerId, children, onClick, ...restProps } = props
  const context = React.useContext(DrawerContext)

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event)
      context.toggleDrawer(drawerId)
    },
    [drawerId, context]
  )

  return (
    <Button {...restProps} as="button" onClick={handleOnClick}>
      {children}
    </Button>
  )
}
