import * as React from 'react'
import { Nav } from './nav'
import { OrgSkillsTable } from './table'
import { TableProvider } from './table/context/table'
import { useRailsContext } from 'components/rails-context'
import { deserialize } from '../../api/utils/deserialize'
import { OrgSkillsTableRow } from './table/data'
import { Skill } from 'store/modules/skills'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { parseISO } from 'date-fns'
import { IJsonPatch, onPatch } from 'mobx-state-tree'
import { DeletedSkillContext } from './deleted-skill-context'

const patchRegex = /\/(?<module>.+?)\/data\/(?<id>.+?)\//

export const OrgSkillsPage = observer(() => {
  const [allLoading, setAllLoading] = React.useState(true)
  const [data, setData] = React.useState<OrgSkillsTableRow[] | null>(null)

  const { skills } = store
  const defaultRows = React.useMemo(() => {
    return skills.forOrg.flatMap(toRow)
  }, [skills])

  const fetchAllSkills = async () => {
    const rows = await fetchSkillInsights()
    setData(rows)
    setAllLoading(false)
  }

  const [lastDeletedSkillId, setLastDeletedSkillId] = React.useState<
    string | null
  >(null)

  React.useEffect(() => {
    fetchAllSkills()
  }, [])

  React.useEffect(() => {
    // Don't start listening for changes until all the initial store boot has finished
    if (allLoading) return

    // Listen to changes to skills and skill variants, and refetch insight rows
    const dispose = onPatch(store, async (patch) => {
      const fetched = await processPatch(patch)
      if (!fetched) return
      setData((rows) => {
        if (!rows) return rows
        return rows.map((row) => (row.id === fetched.id ? fetched : row))
      })
    })

    return () => dispose()
  }, [allLoading])

  React.useEffect(() => {
    if (lastDeletedSkillId) {
      setData((rows) => {
        if (!rows) return rows
        return rows.filter((row) => row.id !== lastDeletedSkillId)
      })
    }
  }, [lastDeletedSkillId])

  const { request } = useRailsContext()

  const defaultOpen = request.query.open_drawer ? true : false

  return (
    <DeletedSkillContext.Provider
      value={{ lastDeletedSkillId, setLastDeletedSkillId }}
    >
      <Nav onRefresh={() => fetchAllSkills()} defaultOpen={defaultOpen} />
      <TableProvider loading={allLoading} data={data || defaultRows}>
        <OrgSkillsTable />
      </TableProvider>
    </DeletedSkillContext.Provider>
  )
})

async function fetchSkillInsights() {
  const res = await fetch('/api/v1/skill_insights')
  if (!res.ok) return []
  const json = await res.json()
  const deserialized = deserialize(json)
  if (!deserialized?.data) return []
  const insights = deserialized.data.skillInsights as Record<
    string,
    OrgSkillsTableRow
  >

  return Object.values(insights).map(normalizeRow)
}

async function fetchSkillInsight(id: string) {
  const res = await fetch(`/api/v1/skill_insights/${id}`)
  if (!res.ok) return
  const json = await res.json()
  const deserialized = deserialize(json)
  if (!deserialized?.data) return
  const insights = deserialized.data.skillInsights as Record<
    string,
    OrgSkillsTableRow
  >

  return Object.values(insights).map(normalizeRow)[0]
}

function normalizeRow(row: OrgSkillsTableRow) {
  return {
    ...row,
    teamIds: row.teamIds.map((id) => id.toString()),
    variantId: row.variantId.toString(),
    first3UserIds: row.first3UserIds.map((id) => id.toString()),
    lastEdited: parseISO(`${row.lastEdited}`),
  }
}

function toRow(skill: Skill): OrgSkillsTableRow | [] {
  const variant = skill.defaultVariant
  if (!variant) return []
  return {
    id: skill.id,
    name: skill.name,
    levels: 0,
    teamIds: [],
    teamCount: 0,
    userCount: 0,
    variantId: variant.id,
    editableBy: skill.editableBy,
    lastEdited: variant.updatedAt,
    description: skill.description,
    imageUrl: skill.imageUrl,
    commentCount: 0,
    first3UserIds: [],
    positionCount: 0,
    needsAttention: false,
    variantCount: skill.skillVariants.length,
  }
}

async function processPatch(patch: IJsonPatch) {
  const { id, module } = patch.path.match(patchRegex)?.groups ?? {}
  if (!id || !module) return

  const skillId: string | undefined =
    module === 'skills' ? id : store.skillVariants.byId(id)?.skill.id
  if (!skillId) return

  return await fetchSkillInsight(id)
}
