import { CellContext, ColumnDef } from '@tanstack/react-table'
import { store } from 'store/index'
import { ActionsCell, NameCell, PeopleCell } from './cells'
import { formatDate } from 'app/packs/src/utils/date-helpers'
import { Skill } from 'store/modules/skills'

export type OrgSkillsTableRow = {
  id: string
  variantId: string
  name: string
  description: string | null
  imageUrl?: string | null
  lastEdited: Date
  levels: number
  teamIds: string[]
  teamCount: number
  positionCount: number
  userCount: number
  first3UserIds: string[]
  commentCount: number
  editableBy: Skill['editableBy']
  needsAttention: boolean
  variantCount: number
}

export class Data {
  get columns() {
    return [
      {
        accessorKey: 'name',
        cell: NameCell,
        enableGlobalFilter: true,
        header: 'Name',
        id: 'name',
      },
      {
        accessorKey: 'lastEdited',
        cell: this.renderDate,
        enableGlobalFilter: false,
        header: 'Last edited',
        id: 'lastEdited',
        sortingFn: 'datetime',
      },
      {
        accessorKey: 'variantCount',
        header: 'No. Variants',
        enableGlobalFilter: false,
        id: 'variantCount',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'levels',
        header: 'No. Levels',
        enableGlobalFilter: false,
        id: 'levels',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'teamCount',
        enableGlobalFilter: false,
        header: 'Teams using',
        id: 'teamCount',
        sortingFn: 'alphanumeric',
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'teamIds',
        enableGlobalFilter: false,
        id: 'teams',
        filterFn: 'arrIncludesSome',
      },
      {
        accessorKey: 'positionCount',
        enableGlobalFilter: false,
        header: 'Positions using',
        id: 'positions',
        sortingFn: 'alphanumeric',
        filterFn: 'equals',
      },
      {
        accessorKey: 'userCount',
        cell: PeopleCell,
        enableGlobalFilter: false,
        id: 'users',
        header: 'People using',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'needsAttention',
        id: 'needAttention',
        enableGlobalFilter: false,
        filterFn: 'equals',
      },
      {
        accessorKey: 'editableBy',
        enableGlobalFilter: false,
        id: 'editPermission',
      },
      {
        id: 'actions',
        enableGlobalFilter: false,
        cell: ActionsCell,
      },
    ] satisfies ColumnDef<OrgSkillsTableRow, never>[]
  }

  get variantsEnabled() {
    return store.featureGates.canAccess('skill_variants')
  }

  private renderDate(row: CellContext<OrgSkillsTableRow, Date | undefined>) {
    const date = row.getValue()
    if (!date) return '-'
    return formatDate(date, { relative: true })
  }
}
