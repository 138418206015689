import type { ColumnFiltersState } from '@tanstack/react-table'
import { Memoize } from 'typescript-memoize'
import stringify from 'safe-stable-stringify'
import { OrgSkillGapsTableRow } from './data'
import { store } from 'store/index'
import { overlaps } from 'app/packs/src/utils/array-helpers'

export class Filters {
  constructor(private readonly data: OrgSkillGapsTableRow[]) {}

  get editPermissionOptions() {
    return [
      { label: 'Editable by Admins only', value: 'admin_only' },
      { label: 'Editable by Admins and Editors', value: 'admin_editors' },
    ]
  }

  get teamOptions() {
    return store.teams.sortedForCurrentUser.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  }

  totalCount(columnFilters: ColumnFiltersState, searchFilter: string) {
    return this.filtered(columnFilters, searchFilter).length
  }

  @Memoize((columnFilters: ColumnFiltersState, searchFilter?: string) => {
    return `${searchFilter}-${stringify(columnFilters)}`
  })
  private filtered(columnFilters: ColumnFiltersState, searchFilter?: string) {
    return this.data.filter((row) => {
      if (!this.rowMatchesFilters(row, columnFilters)) return false
      if (!searchFilter?.length) return true

      return !row.name.toLowerCase().includes(searchFilter.toLowerCase())
    })
  }

  private rowMatchesFilters(
    row: OrgSkillGapsTableRow,
    columnFilters: ColumnFiltersState
  ) {
    if (!columnFilters.length) return true
    for (const filter of columnFilters) {
      switch (filter.id) {
        case 'editPermission':
          // Uncomment if we want to support editing.
          // if (row.editableBy !== filter.value) return false
          break
        case 'teams':
          if (!overlaps(filter.value as string[], row.teamIds)) return false
          break
      }

      return true
    }
  }
}
