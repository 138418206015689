import { Tag } from 'src/design-system'
import React from 'react'
import { Skill } from 'store/modules/skills'

export function UsedBySection({ skill }: { skill: Skill }) {
  const usedByTeams = skill.teams
  const usedByPositions = skill.positions

  if (!usedByTeams.length && !usedByPositions.length) {
    return null
  }
  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-bold text-xl">Used by</h3>
      {!!usedByTeams.length && (
        <div className="flex gap-8 items-center">
          <h4 className="font-bold text-base text-gray-600 flex gap-4">
            Teams
            <Tag className="font-normal" variant="paper">
              {usedByTeams.length}
            </Tag>
          </h4>
          <p className="ml-auto mb-0 whitespace-nowrap overflow-x-hidden overflow-ellipsis">
            {usedByTeams.map((team) => team.name).join(', ')}
          </p>
        </div>
      )}
      {!!usedByPositions.length && (
        <div className="flex gap-8 items-center">
          <h4 className="font-bold text-base text-gray-600 flex gap-4">
            Positions
            <Tag className="font-normal" variant="paper">
              {usedByPositions.length}
            </Tag>
          </h4>
          <p className="ml-auto mb-0 whitespace-nowrap overflow-x-hidden overflow-ellipsis">
            {usedByPositions.map((position) => position.name).join(', ')}
          </p>
        </div>
      )}
    </div>
  )
}
