import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { DownloadSimple } from '@phosphor-icons/react'

type DownloadButtonProps = {
  defaultOpen?: boolean
  onRefresh?: () => void
}

export const DownloadButton = observer((props: DownloadButtonProps) => {
  const canAddSkills = !!store.currentUser && store.currentUser.isAdminOrEditor
  const canImportSkills = !!store.currentUser && store.currentUser.isAdmin
  const downloadLink = `/orgs/${store.currentUser?.org?.slug}/skills/download.csv`

  return (
    <>
      {(canAddSkills || canImportSkills) && (
        <div className="">
          {canImportSkills && (
            <>
              <Button
                as="a"
                href={downloadLink}
                variant="outline"
                data-turbo-action="advance"
              >
                <DownloadSimple aria-hidden size={16} weight="bold" />
                Download data
              </Button>
            </>
          )}
        </div>
      )}
    </>
  )
})
