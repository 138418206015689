import { CellContext } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { OrgSkillGapsTableRow } from '../data'
import { Tooltip } from 'src/design-system'

export const CoverageCell = observer(
  (data: CellContext<OrgSkillGapsTableRow, unknown>) => {
    const row = data.row.original

    const coverage = row.checkinCoverage
    const count = row.checkinCount
    const users = row.userCount

    const getCoverageColor = (coverage: number) => {
      let bgColor: string
      let textColor: string
      switch (true) {
        case coverage > 90:
          bgColor = 'bg-green-500/75'
          textColor = 'text-green-900'
          break
        case coverage > 50:
          bgColor = 'bg-green-500/25'
          textColor = 'text-green-800'
          break
        case coverage <= 50:
          bgColor = 'bg-yellow-500/25'
          textColor = 'text-yellow-800'
          break
        default:
          bgColor = 'bg-gray-100/50'
          textColor = 'text-gray-800'
          break
      }
      return { bgColor, textColor }
    }

    return (
      <div className="flex">
        <Tooltip
          content={`${count} of ${users} people have checked in against this skill`}
        >
          <div
            className={`rounded-full flex-grow-0 min-w-24 flex justify-center px-3 py-0.5 ${
              getCoverageColor(coverage).bgColor
            }`}
          >
            <span className={`${getCoverageColor(coverage).textColor}`}>
              {coverage}%
            </span>
          </div>
        </Tooltip>
      </div>
    )
  }
)
